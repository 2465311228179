/* You can add global styles to this file, and also import other style files */
@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("../src/css/simple-sidebar.css");
@import "../node_modules/angular-calendar/css/angular-calendar.css";

/* @import url('https://fonts.googleapis.com/css?family=Concert+One&display=swap'); */
/* @import url('https://fonts.googleapis.com/css?family=Forum&display=swap'); */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/*----------------------------------------------------------------------------------------*/
/*----------------------------------- Admin Panel CSS ------------------------------------*/
/*----------------------------------------------------------------------------------------*/
.leads-header {
    position: relative;
    z-index: 9999;
}
.leads-header .navbar-brand img {
    width: 30%;
}
.leads-header #sidebar-wrapper {
    /* background-color: #20672f !important; */
    background-image: url('./assets/img/bg.jpg');
    box-shadow: 0 0 10px #333333;
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 10000;
    height: 100%;
    overflow-x: hidden !important;
    overflow-y: auto;
    border-right-color: #20672f !important;
}
.leads-header #sidebar-wrapper .nav-item {
    background-image: linear-gradient(-90deg, #20672f, #47b129, #20672f);
    color: #ffffff;
    box-shadow: -1px 0px 3px 0px rgba(51,51,51,1);
    cursor: pointer;
}
.leads-header #sidebar-wrapper .nav-item .nav-link{
    padding: .75rem 1.25rem;
}
.leads-header #sidebar-wrapper .dropdown .dropdown-menu {
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    width: 100%;
    border-radius: 0px !important;
    padding: 0px;
    border: 0px;
    background-color: #20672f !important;
    transform: none !important;
}
.leads-header #sidebar-wrapper .dropdown .dropdown-menu .dropdown-item {
    background-color: rgba(0,0,0,0.3);
    border-bottom: 1px solid #000000;
    color: #ffffff;
    padding: .75rem 1.25rem;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    position: relative;
}
.leads-header #sidebar-wrapper .dropdown .dropdown-menu .dropdown-item:first-child {
    border-top: 1px solid #333333;
}
.leads-header #sidebar-wrapper .dropdown .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0px;
}

 #wrapper-section {
    margin-top: 5%;
}

#wrapper-section .MatTableCnt {
    margin-left: 2% !important;
    margin-right: 2% !important;
}
#wrapper-section table th {
    font-weight: bold;
    font-size: 14px;
}

.example-form {
    box-shadow: 0 0 10px #777777;
    margin-left: 2% !important;
    margin-right: 2% !important;
    padding: 20px;
}

@media screen and (min-width: 768px) {
    .leads-header #sidebar-wrapper::-webkit-scrollbar {
        width: 2px;
    }
    .leads-header #sidebar-wrapper::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    .leads-header #sidebar-wrapper::-webkit-scrollbar-thumb {
        background: #888;
    }
    .leads-header #sidebar-wrapper::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .leads-header .navbar {
        position: fixed;
        top: 0;
        left: 17.6%;
        width: 82.4%;
        box-shadow: 0 0 10px #999999;
    }
    #wrapper-section {
        width: 82.4%;
        margin-left: 17.6%;
        margin-top: 6%;
    }
    #sidebar-wrapper .dropdown {
        display: inline-block;
    }
}

@media screen and (max-width: 912px) {
    .leads-header #sidebar-wrapper::-webkit-scrollbar {
        width: 2px;
    }
    .leads-header #sidebar-wrapper::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    .leads-header #sidebar-wrapper::-webkit-scrollbar-thumb {
        background: #888;
    }
    .leads-header #sidebar-wrapper::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .leads-header .navbar {
        position: fixed;
        top: 0;
        left: 17.6%;
        width: 82.4%;
        box-shadow: 0 0 10px #999999;
    }
    #wrapper-section {
        width: 82.4%;
        margin-left: 17.6%;
        margin-top: 30%;
    }
    #sidebar-wrapper .dropdown {
        display: inline-block;
    }
}

@media screen and (max-width: 768px) {
    .leads-header .navbar.ml-auto {
        margin-left: 0px !important;
    }
    .btn .nav-link{
        height: 5px !important;
        padding:2px !important;
    }
    .nav-item {
        height: 15px !important;
        padding:2px !important;
        top: 5px !important;
    }
    #userdiv{
        height: 5px !important;
        padding:2px !important;
    }
    .leads-header .navbar-nav .nav-link {
        display: block;
        width: 100%;
        text-align: center;
    }
    .leads-header .navbar-brand img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
    .leads-header .navbar .navbar-nav {
        background-color: #f2f2f2;
        width: 100%;
        margin: 0;
    }
    .leads-header .navbar #menu-toggle {
        position: absolute;
        top: 10px;
        right: 10px;
        text-align: right;
    }
    .leads-header .navbar #menu-txt-toggle {
        display: none;
    }
}

/*-------------- Toggle Classes -----------------*/

.slideOutSideMnu {
    width: 5% !important;
}
.slideInSideMnu {
    width: 5% !important;
}
.slideOutMenutxt {
    width: 100%;
}
.slideInMenutxt {
    width: 28%;
}

.disableicon {
    pointer-events: none;
    opacity: 0.5;
}
.enableicon {
    pointer-events: auto;
    opacity: 1;
}
.drp-togg-w {
    min-width: 4.2rem !important;
}

/*-------------- End Toggle Classes -------------------*/


/*----------------------------------------------------------------------------------------*/
/*--------------------------------End Admin Panel CSS ------------------------------------*/
/*----------------------------------------------------------------------------------------*/



/*----------------------------------------------------------------------------------------*/
/*-------------------------------- Starting Frontend CSS ---------------------------------*/
/*----------------------------------------------------------------------------------------*/


#FE-Header .FE-Header-comp {
    background: #006600;
}
#FE-Header .FE-Header-comp .ed-com-t1-left ul {
    margin-bottom: 0px;
}
#FE-Header .FE-Header-comp .ed-com-t1-left ul li {
    float: left;
    display: inline-block;
}
#FE-Header .FE-Header-comp .ed-com-t1-left ul li a {
    color: #a3adb9;
    padding: 5px 14px;
    line-height: 30px;
    border-right: 1px solid #006600;
    font-size: 12px;
}
#FE-Header .FE-Header-comp .ed-com-t1-right{
    float: right;
}

#FE-Header .FE-Header-comp .ed-com-t1-right ul li {
    float: left;
    display: inline-block;
}
#FE-Header .FE-Header-comp .ed-com-t1-right ul li a {
    color: #fff;
    padding: 7px 12px;
    line-height: 30px;
    font-size: 12px;
}
#FE-Header .FE-Header-comp .ed-com-t1-right ul li:nth-child(1) a {
    background: #fbe33b;
    text-decoration: none;
}
#FE-Header .FE-Header-comp .ed-com-t1-right ul li:nth-child(2) a {
    background: #006600;
}
#FE-Header .FE-Header-comp .ed-com-t1-right ul li:nth-child(3) a {
    background: #fbe33b;
    text-decoration: none;
}
#FE-Header .FE-Header-comp .ed-com-t1-right ul li:nth-child(5) a {
    background: #fbe33b;
    text-decoration: none;
}
#FE-Header .FE-Header-comp .ed-com-t1-social {
    position: relative;
    overflow: hidden;
    margin-top: -5px;
    float: right;
    margin-right: 25px;
}
#FE-Header .FE-Header-comp .ed-com-t1-social ul li {
    float: left;
    list-style-type: none;
    color: #203245;
    line-height: 24px;
}
#FE-Header .FE-Header-comp .ed-com-t1-social ul li a i {
    width: 30px;
    height: 30px;
    /* background: #006600; */
    color: #92a5bb;
    padding: 12px 4px;
    text-align: center;
    border-right: 1px solid #006600;
}

.FE-Header-Navbar {
    border-bottom: 1px solid #006600;
    box-shadow: 0 0px 10px #006600;
}
.FE-Header-Navbar .navbar-brand img {
    width: 90%;
}
.FE-Header-Navbar .navbar-nav li {
    color: #203245;
    line-height: 24px;
}
.FE-Header-Navbar .navbar-nav li a {
    color: #000000 !important;
    padding: 10px 10px;
    line-height: 15px;
    font-size: 16px;
    font-weight: 500;
    position: relative;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}
.FE-Header-Navbar .navbar-nav > li > a:hover {
    color: #fbe33b;
    border-bottom: 2px solid #fbe33b;
    transition: all ease 0.6s;
}
.FE-Header-Navbar .navbar-nav > li.active > a {
    color: #fbe33b !important;
    border-bottom: 0px;
}

.FE-Header-SubMenu {
    background-color: #006600;
}
.FE-Header-SubMenu ul {
    padding: 5px 0;
    width: 100%;
    position: relative;
}
.FE-Header-SubMenu ul li {
    list-style: none;
    display: inline;
    color: #a3adb9;
    cursor: pointer;
    text-align: center;
    padding: 5px 15px;
}
.FE-Header-SubMenu ul li:hover {
    color: #ffffff;
}
.FE-Header-SubMenu ul li:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

#FE-BannerSlider .carousel-inner .carousel-caption {
    bottom: 35% !important;
}
#FE-BannerSlider .carousel-inner .carousel-caption h3 {
    font-size: 36px !important;
    font-weight: bold;
    letter-spacing: 2px;
    text-shadow: 2px 2px 0 #111111, 2px -2px 0 #111111, -2px 2px 0 #111111, -2px -2px 0 #111111, 2px 0px 0 #111111, 0px 2px 0 #111111, -2px 0px 0 #111111, 0px -2px 0 #111111, 1px 1px 0px rgba(206,89,55,0);
}
#FE-BannerSlider .carousel-inner .carousel-caption h3 span {
    text-shadow: 2px 2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff, -2px -2px 0 #ffffff, 2px 0px 0 #ffffff, 0px 2px 0 #ffffff, -2px 0px 0 #ffffff, 0px -2px 0 #ffffff, 1px 1px 0px rgba(206,89,55,0);
}
#FE-BannerSlider .carousel-inner .carousel-caption .clr1 {
    color: #fbe33b !important;
}
#FE-BannerSlider .carousel-indicators li {
    opacity: 1;
}
#FE-BannerSlider .carousel-indicators .active {
    background-color: #fbe33b;
}
.FE-BannerSec .FE-BannerSliderIcons {
    position: relative;
}
.FE-BannerSec .FE-BannerSliderIcons ul {
    width: 37%;
    position: absolute;
    left: 50%;
    bottom: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -os-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.FE-BannerSec .FE-BannerSliderIcons ul li {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.8);
    width: 85px;
    height: 85px;
    border-radius: 100%;
    padding: 20px 12px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    margin: 10px;
}
.FE-BannerSec .FE-BannerSliderIcons .SliderIcons:last-child {
    margin-right: 0px;
}
.FE-BannerSec .FE-BannerSliderIcons .SliderIcons:hover {
    background-color: #fbe33b;
    transition: all ease 0.4s;
    transform: scale(1.05);
}

.DiscoverMore .Disc {
    border: 1px solid transparent;
    border: 1px solid #cccccc;
    box-shadow: 0 0 10px #cccccc;
    margin-bottom: 20px;
    position: relative !important;
    overflow: hidden !important;
}
.DiscoverMore .Disc .Disc-sec {
    position: relative;
}
.DiscoverMore .Disc .Disc-sec img {
    cursor: pointer;
}
.DiscoverMore .Disc .Disc-cnt {
    text-align: center;
    cursor: default;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    transition: all ease 0.4s;
    transform: scale(0);
}
.DiscoverMore .Disc .Disc-cnt h3 {
    font-weight: bold;
}
.DiscoverMore .Disc .Disc-cnt hr {
    margin-top: -10px;
}
.DiscoverMore .Disc .Disc-cnt p {
    text-align: justify;
}
.DiscoverMore .Disc:hover .Disc-cnt {
    display: block;
    transition: all ease 0.4s;
    transform: scale(1);
}

footer {
    margin-top: 3%;
}
footer .Footer-Caption {
    background-color: #fbe33b;
    color: #ffffff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Forum', cursive;
}
footer .Footer-Caption h3 {
    font-size: 20px;
    line-height: 36px;
}
footer .Main-Footer {
    background-color: #01172f;
    color: #999999;
    padding-top: 15px;
    cursor: pointer;
}

#Contact-Form {
    position: relative;
    margin-top: 10px;
}
#Contact-Form .Contact-Form-sec {
    position: absolute;
    top: 0;
    left: 10%;
    width: 30%;
    border: 5px dashed #000000;
    border-radius: 7px;
    background-color: #ffffff;
    padding-top: 10px;
    padding-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
#Contact-Form .Contact-Form-sec .card {
    border: 0px !important;
}
#Contact-Form .Contact-Form-sec .card h3 {
    font-weight: bold;
}
#Contact-Form .Contact-Form-sec .card h2 {
    font-size: 48px;
    font-weight: bold;
}
#Contact-Form .Contact-Form-sec .card h2 span {
    color: #fbe33b;
}
#Contact-Form iframe {
    margin-top: 5%;
}

@media screen and (min-width: 768px) and (max-width: 480px) {

}

 @media screen and (max-width: 768px) {
    .FE-Header-Navbar .navbar-brand {
        width: 70% !important;
    }
    #FE-Header .FE-Header-comp .navbar-toggler-icon {
        float: left;
    }
    #FE-Header .FE-Header-comp .ed-com-t1-left ul {
        padding-inline-start: 0px !important;
        display: none;
    }
    #FE-Header .FE-Header-comp .ed-com-t1-left ul li {
        display: block;
        float: none;
    }
    .FE-Header-SubMenu ul li {
        display: block;
        width: 100%;
        text-align: center;
    }
    #FE-BannerSlider .carousel-inner .carousel-caption {
        bottom: 0% !important;
    }
    #FE-BannerSlider .carousel-inner .carousel-caption h3 {
        font-size: 24px !important;
    }
    .FE-BannerSec .FE-BannerSliderIcons {
        margin-top: 20px;
    }
    .FE-BannerSec .FE-BannerSliderIcons ul {
        width: 100%;
        position: relative;
        left: 0%;
        bottom: 0%;
        -webkit-transform: translate(0%, 0%);
        -moz-transform: translate(0%, 0%);
        -os-transform: translate(0%, 0%);
        transform: translate(0%, 0%);
        padding-inline-start: 0px;
    }
    .FE-BannerSec .FE-BannerSliderIcons ul li {
        display: inline-block;
        margin: 2px;
    }
    #Contact-Form .Contact-Form-sec {
        position: relative;
        top: 0;
        left: 0%;
        width: 100%;
    }
    #Contact-Form iframe {
        margin-top: 1%;
    }

}



/*----------------------------- Common Classes ------------------------------*/

.btn-ext {
    background-color: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    border: 1px solid #fbe33b;
}
.btn-ext:hover {
    background-color: #fbe33b;
    border: 1px solid #fbe33b;
    color: #ffffff;
}

.Title-Heading {
    text-align: center;
}
.Title-Heading span {
    font-size: 42px !important;
    font-weight: bold;
    position: relative;
    font-family: 'Concert One', cursive !important;
    border-top: 2px dotted #111111;
}
.Title-Heading span i {
    color: #fbe33b;
    font-style: normal;
}
.Title-Heading span:before {
    content: "";
    display: block;
    background-image: url(src/assets/img/cap.png);
    background-size: 33px auto;
    background-position: left top;
    background-repeat: no-repeat;
    padding: 40px;
    position: absolute;
    top: -18px;
    left: -25px;
    z-index: 2;
}
.Title-Heading p {
    font-size: 22px !important;
    font-weight: bold;
    position: relative;
    font-family: 'Concert One', monospace !important;
}
.Title-Heading p i {
    color: #fbe33b;
    font-style: normal;
}
.Title-Heading p:before {
    content: "";
    display: block;    
    padding: 40px;
    position: absolute;
    top: -18px;
    left: -25px;
    z-index: 2;
}
.line-hgt-36 {
    line-height: 36px;
}
h2.hdr-font, ul.hdr-font li {
    font-size: 30px;
    font-weight: bold;
    text-decoration: underline;
}
/* p {
    font-family: 'Forum', cursive !important;
    font-size: 12px;
} */


.example-form .mat-form-field-appearance-outline .mat-form-field-wrapper {

  /* margin: 0 !important;
  padding: 0 !important; */
}
.example-full-width {
    width: 100%;
  }


.modal {
    top: 8% !important;
    /* z-index: 999 !important; */
    z-index: 100 !important;
}
.modal .mat-select-panel {
    z-index: 9999 !important;
}
.modal-backdrop.show {
    z-index: 0 !important;
}
.example-form {
  box-shadow: 0 0 10px #777777;
  margin-left: 2% !important;
  margin-top: 10% !important;
  /* margin-right: 2% !important; */
  padding: 20px;
  background-color: #ffffff !important;
}
.example-container {
  /* height: 400px; */
  /* overflow: auto; */
}
table {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    min-width: 284px;
}

th.mat-header-cell {
    text-align: left;
    max-width: 300px;
}

.view_response{
    display: inline-table !important;
    border: 1px solid #ccc;
    text-align: center;
  }
  .modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}
.txtLength{

    /* white-space: nowrap; */
    overflow:hidden;
    text-overflow: ellipsis;
    display:inline-block;
    width : 100px;

}

.warning_text{
  color: red;
  font-size:10px;
}
.warning_text1{
  color: red;

}


.loader {
  z-index: 999;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.pad-top-18 {
    padding-top: 18px;
  }

.height-68{
    height: 68px;
}

.enabled-mode {
    height: 22px;
    filter: brightness(112%);
  }

  .mag-left-15 {
    margin-left: 15px;
  }
  
  .cdk-overlay-container {
    z-index: 500 !important;
}